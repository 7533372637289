import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteFallback from './RouteFallback';
import loadable from '@loadable/component';
import RedirectPage from '../Components/RedirectPage';

import { PLAYSTORE_LINK, APPSTORE_LINK } from '../Constants';

const LazyHome = loadable(
  () => import(/* webpackChunkName: "Home" */ '../Pages/Home/index'),
  {
    fallback: <RouteFallback />
  }
);
const LazyPolicy = loadable(
  () =>
    import(/* webpackChunkName: "Policy" */ '../Pages/Policy/PrivacyPolicy'),
  {
    fallback: <RouteFallback />
  }
);
const LazyTOS = loadable(
  () =>
    import(
      /* webpackChunkName: "T&C" */ '../Pages/TermsandConditions/TermsandConditions'
    ),
  {
    fallback: <RouteFallback />
  }
);
const LazyFAQ = loadable(
  () => import(/* webpackChunkName: "FAQ" */ '../Pages/Faq/Faq'),
  {
    fallback: <RouteFallback />
  }
);

const LazyVineAppLinks = loadable(
  () =>
    import(/* webpackChunkName: "vine_app_links" */ '../Pages/AppLinks/Vine'),
  {
    fallback: <RouteFallback />
  }
);

const LazyLLFAppLinks = loadable(
  () =>
    import(
      /* webpackChunkName: "vine_app_links" */ '../Pages/LetLoveFlyApp/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const WeddingAppLinks = loadable(
  () =>
    import(
      /* webpackChunkName: "vine_app_links" */ '../Pages/WeddingApp/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const LazyLLFPolicy = loadable(
  () =>
    import(/* webpackChunkName: "Policy" */ '../Pages/LetLoveFlyPolicy/index'),
  {
    fallback: <RouteFallback />
  }
);
const LazyAsianPaintsPolicy = loadable(
  () =>
    import(/* webpackChunkName: "Policy" */ '../Pages/AsianPaintsPolicy/index'),
  {
    fallback: <RouteFallback />
  }
);

const LazySuperPremiumApplicatorsPolicy = loadable(
  () =>
    import(
      /* webpackChunkName: "Policy" */ '../Pages/SuperPremiumApplicators/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const LazyACPolicy = loadable(
  () =>
    import(
      /* webpackChunkName: "Policy" */ '../Pages/AnkushChinmayaPolicy/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const LazyTMPolicy = loadable(
  () =>
    import(/* webpackChunkName: "Policy" */ '../Pages/TmKaMagicPolicy/index'),
  {
    fallback: <RouteFallback />
  }
);

const LazyDeleteAccount = loadable(
  () =>
    import(/* webpackChunkName: "Policy" */ '../Pages/DeleteAccountPage/index'),
  {
    fallback: <RouteFallback />
  }
);

const TmKaMagicAppLinks = loadable(
  () =>
    import(
      /* webpackChunkName: "vine_app_links" */ '../Pages/TmKaMagicApp/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const EemaAppLinks = loadable(
  () =>
    import(/* webpackChunkName: "vine_app_links" */ '../Pages/EemaApp/index'),
  {
    fallback: <RouteFallback />
  }
);

const IntegrityChampAppLinks = loadable(
  () =>
    import(
      /* webpackChunkName: "vine_app_links" */ '../Pages/IntegrityChampApp/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const LazyPRPolicy = loadable(
  () =>
    import(
      /* webpackChunkName: "Policy" */ '../Pages/PolycabRefreshPolicy/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const MspirePolicy = loadable(
  () => import(/* webpackChunkName: "Policy" */ '../Pages/MspirePolicy/index'),
  {
    fallback: <RouteFallback />
  }
);

const VibeWithTheNightPolicy = loadable(
  () =>
    import(
      /* webpackChunkName: "Policy" */ '../Pages/VibeWithTheNightPolicy/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const LazyRGPolicy = loadable(
  () =>
    import(/* webpackChunkName: "Policy" */ '../Pages/RidomilGoldPolicy/index'),
  {
    fallback: <RouteFallback />
  }
);

const MizoMusicFestivalPolicy = loadable(
  () =>
    import(
      /* webpackChunkName: "Policy" */ '../Pages/MizoMusicFestivalPolicy/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const NipponIndiaMutualFundpoPolicy = loadable(
  () =>
    import(
      /* webpackChunkName: "Policy" */ '../Pages/NipponIndiaMutualFundpolicy/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const LazyNipponIndiaMutualFundLivePolls = loadable(
  () =>
    import(
      /* webpackChunkName: "vine_app_links" */ '../Pages/NipponIndiaMutualFundLivePoll/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const LazyNipponIndiaMutualFundAppLinks = loadable(
  () =>
    import(
      /* webpackChunkName: "vine_app_links" */ '../Pages/NipponIndiaMutualFundApp/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const LazyALGlobalConferenceAppLinks = loadable(
  () =>
    import(
      /* webpackChunkName: "vine_app_links" */ '../Pages/ALGlobalConferenceApp/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const LazyMspireAppLinks = loadable(
  () =>
    import(/* webpackChunkName: "vine_app_links" */ '../Pages/MspireApp/index'),
  {
    fallback: <RouteFallback />
  }
);

const Maa10Policy = loadable(
  () => import(/* webpackChunkName: "Policy" */ '../Pages/Maa10Policy/index'),
  {
    fallback: <RouteFallback />
  }
);

const ALGlobalConferencePolicy = loadable(
  () =>
    import(
      /* webpackChunkName: "Policy" */ '../Pages/ALGlobalConferencePolicy/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const EemaPolicy = loadable(
  () => import(/* webpackChunkName: "Policy" */ '../Pages/EemaPolicy/index'),
  {
    fallback: <RouteFallback />
  }
);

const CarlsbergPolicy = loadable(
  () =>
    import(/* webpackChunkName: "Policy" */ '../Pages/carlsbergPolicy/index'),
  {
    fallback: <RouteFallback />
  }
);

const APWeddingPolicy = loadable(
  () =>
    import(/* webpackChunkName: "Policy" */ '../Pages/APWeddingPolicy/index'),
  {
    fallback: <RouteFallback />
  }
);

const PolycabAppLinks = loadable(
  () =>
    import(
      /* webpackChunkName: "vine_app_links" */ '../Pages/PolycabApp/index'
    ),
  {
    fallback: <RouteFallback />
  }
);

const MainRoute = () => (
  <React.Suspense fallback={<RouteFallback />}>
    <Switch>
      <Route path="/" exact component={LazyHome} />
      <Route path="/ViNe" component={LazyVineAppLinks} />
      <Route exact path="/mspire2024/applink" component={LazyMspireAppLinks} />
      <Route path="/AnkushWedsChinmaya/appLink" component={WeddingAppLinks} />
      <Route exact path="/LetLoveFly/appLink" component={LazyLLFAppLinks} />
      <Route exact path="/termsandconditions" component={LazyTOS} />
      <Route exact path="/privacypolicy" component={LazyPolicy} />
      <Route exact path="/frequentlyaskedquestions" component={LazyFAQ} />
      <Route
        path="/playStore"
        exact
        render={(props) => <RedirectPage {...props} url={PLAYSTORE_LINK} />}
      />
      <Route
        path="/appStore"
        exact
        render={(props) => <RedirectPage {...props} url={APPSTORE_LINK} />}
      />
      <Route exact path="/LetLoveFly/privacypolicy" component={LazyLLFPolicy} />
      <Route
        exact
        path="/AsianPaints/privacypolicy"
        component={LazyAsianPaintsPolicy}
      />
      <Route
        exact
        path="/SuperPremiumApplicators/privacypolicy"
        component={LazySuperPremiumApplicatorsPolicy}
      />
      <Route
        exact
        path="/AnkushWedsChinmaya/privacypolicy"
        component={LazyACPolicy}
      />
      <Route exact path="/TMKAMAGIC/privacypolicy" component={LazyTMPolicy} />
      <Route exact path="/TMKAMAGIC/appLink" component={TmKaMagicAppLinks} />
      <Route exact path="/DeleteAccount" component={LazyDeleteAccount} />
      <Route
        exact
        path="/PolycabRefresh/privacypolicy"
        component={LazyPRPolicy}
      />
      <Route exact path="/RidomilGold/privacypolicy" component={LazyRGPolicy} />
      <Route exact path="/POLYCAB/appLink" component={PolycabAppLinks} />
      <Route
        exact
        path="/MizoMusicFestival/privacypolicy"
        component={MizoMusicFestivalPolicy}
      />
      <Route
        exact
        path="/NipponIndiaMutualFund/privacypolicy"
        component={NipponIndiaMutualFundpoPolicy}
      />
      <Route
        exact
        path="/NipponIndiaMutualFund/appLink"
        component={LazyNipponIndiaMutualFundAppLinks}
      />
      <Route
        exact
        path="/NipponIndiaMutualFund/LivePolls"
        component={LazyNipponIndiaMutualFundLivePolls}
      />
      <Route
        exact
        path="/ALGlobalConference/privacypolicy"
        component={ALGlobalConferencePolicy}
      />
      <Route
        exact
        path="/ALGlobalConference/appLink"
        component={LazyALGlobalConferenceAppLinks}
      />
      <Route exact path="/Eema/privacypolicy" component={EemaPolicy} />
      <Route exact path="/EEMA/appLink" component={EemaAppLinks} />

      <Route
        exact
        path="/IntegrityChamp/privacypolicy"
        component={CarlsbergPolicy}
      />
      <Route
        exact
        path="/IntegrityChamp/appLink"
        component={IntegrityChampAppLinks}
      />
      <Route
        exact
        path="/APWedding/privacypolicy"
        component={APWeddingPolicy}
      />
      <Route
        exact
        path="/VibeWithTheNight/privacypolicy"
        component={VibeWithTheNightPolicy}
      />
      <Route exact path="/Mspire/privacypolicy" component={MspirePolicy} />
      <Route exact path="/Maa10/privacypolicy" component={Maa10Policy} />
    </Switch>
  </React.Suspense>
);

export default MainRoute;
